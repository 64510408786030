module.exports = {
  comm: {
    home: 'Strona główna',
    pricing: 'Cennik',
    blog: 'Klonowanie głosu i API',
    language: 'język:',
    select_language: 'wybierz język',
    txt2voice: 'Tekst na mowę',
    voice2txt: 'Mowa na tekst',
    voiceclone: 'Klonowanie głosu',
    video2txt: 'Wideo na tekst',
    footer_help: 'Potrzebujesz pomocy? Wyślij nam e-mail:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Zaloguj się',
    login_desc: 'Zaloguj się, aby uzyskać dostęp do swojego konta TikTok Voice Generator',
    logout: 'Wyloguj się',
  },
  txt2voice: {
    title: 'Generator głosu TikTok: Generowanie zabawnych głosów AI TikTok',
    description: 'Generuj i pobieraj za darmo zabawne głosy AI TikTok, takie jak głos Jessie, głos C3PO, głos ghostface...',
    keywords: 'Generator głosu TikTok, Głos AI TikTok, Generator głosu Tik Tok',
    main_title_p01: 'Generator głosu TikTok',
    main_title_p02: 'Generowanie zabawnych głosów TikTok: głos Jessie, głos C3PO, głos ghostface',
    main_textarea_holder: 'Wpisz lub wklej tekst tutaj',
    main_genvoice: 'generuj',
    main_generating: 'generowanie',
    main_input_empty: 'proszę wpisać lub wkleić tekst',
    daily_usage_limit_msg: '1) Osiągnięto dzienny maksymalny limit użycia. Proszę wrócić jutro. 2) Jeśli pilnie potrzebujesz więcej użyć, proszę skontaktuj się z nami mailowo.',
    text_max_prompt1: 'Proszę ograniczyć liczbę słów do',
    text_max_prompt2: 'słów lub mniej!',
    popup_nologin_title: 'Ulepsz swój plan subskrypcji',
    popup_nologin_desc: 'Odblokuj więcej czasu generowania głosu, przechodząc na subskrypcję premium.',
    popup_nologin_btntext: 'Przeglądaj plany subskrypcji',
    popup_nosub_title: 'Ulepsz swój plan subskrypcji',
    popup_nosub_desc: 'Odblokuj więcej czasu generowania głosu, przechodząc na subskrypcję premium.',
    popup_nosub_btntext: 'Przeglądaj plany subskrypcji',
    popup_sublimit_title: 'Subskrypcja wyczerpana, proszę o ulepszenie',
    popup_sublimit_desc: 'Ulepsz swoje doświadczenie dzięki naszym funkcjom premium i nieograniczonemu dostępowi.',
    popup_sublimit_btntext: 'Przejdź do zakupu więcej',
  },
  pricing: {
    new_features_alert: "📣 Uruchomiliśmy usługi 🎙️ Klonowania Głosu i 🤖 API dla płatnych członków! (Po dokonaniu płatności, wyślij e-mail na adres tiktokaivoicetool@gmail.com, aby uzyskać pomoc w korzystaniu z tych funkcji). Dodatkowo, nie wahaj się napisać do nas w sprawie jakichkolwiek próśb o dostosowanie😃",
    pricing_title: 'Od indywidualnych twórców po największe przedsiębiorstwa, mamy plan dla Ciebie.',
    pricing_desc: 'Ponad 100 naturalnych, ludzkich głosów. Ponad 15 języków. i prawie najniższe ceny dla każdego',
    sub_free_name: 'Darmowy',
    sub_free_desc: 'Dla osób, które chcą wypróbować najbardziej zaawansowane audio AI',
    sub_free_content: [
      'Limit 1000 znaków miesięcznie',
      '5 generacji dziennie',
    ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'Dla twórców tworzących premium content dla globalnej publiczności',
    sub_starter_content: [
      '1 klon głosu',
      'Limit 300 000 znaków miesięcznie (około 7 godzin audio)',
      'Nieograniczone pobieranie',
      'Wsparcie e-mail w ciągu 72 godzin',
      'Priorytetowy dostęp do nowych głosów i funkcji',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Dla twórców zwiększających produkcję treści',
    sub_pro_content: [
      'Dostęp do API',
      '3 klony głosu',
      'Limit 1 000 000 znaków miesięcznie (około 24 godzin audio)',
      'Nieograniczone pobieranie',
      'Wsparcie e-mail w ciągu 48 godzin',
      'Priorytetowy dostęp do nowych głosów i funkcji',
    ],
    period_year: 'Rocznie',
    period_month: 'Miesięcznie',
    period_month_short: 'mies.',
    billed_year: 'Rozliczane rocznie',
    most_popular: 'NAJPOPULARNIEJSZY',
    discount_quantity: 'Oszczędź $48',
    buy_btntext: 'ROZPOCZNIJ',
    pay_succ: 'Płatność udana!',
    pay_succ_desc: 'Dziękujemy za zakup. Twoja transakcja została pomyślnie zakończona.',
    pay_succ_btntext: 'Zacznij korzystać z TikTok Voice',
    pay_fail: 'Płatność nie powiodła się!',
    pay_fail_desc: "Przepraszamy, ale Twoja transakcja nie mogła zostać przetworzona. Spróbuj ponownie lub skontaktuj się z naszym zespołem wsparcia pod adresem tiktokaivoicetool@gmail.com.",
    pay_fail_btntext: 'Powrót do strony głównej',
  },
  setting: {
    setting: 'Ustawienia',
    setting_title: 'Zarządzaj tutaj swoim kontem, użytkowaniem i subskrypcją.',
    user_name: 'Imię',
    user_email: 'E-mail',
    user_account: 'Konto',
    user_subscript: 'Aktualny plan',
    user_usage: 'Użycie',
    manage_subscript: 'Zarządzaj subskrypcją',
    use_limit_promote: "Wykorzystałeś 5000 z 10000 miesięcznego limitu znaków.",
    basic_info: 'Podstawowe informacje',
    not_logged_in: 'Nie jesteś zalogowany',
    go_to_login: 'Przejdź do logowania >>',
  },
  login: {
    title: "Generator głosu TikTok",
    subtitle: "Zaloguj się, aby uzyskać dostęp do swojego konta TikTok Voice Generator",
    alreadyLoggedIn: "Jesteś już zalogowany",
    goToHome: "Przejdź do strony głównej"
  },
  faq: {
    quest1: 'Generator głosu TikTok - Najlepsze narzędzie głosu AI TikTok',
    answer1_1: 'To narzędzie do generowania głosów AI TikTok opracowane przez najnowszą technologię tts TikTok, może generować głosy TikTok takie jak głos Jessie (głos żeński), głos Siri, głos ghostface, głos C3PO (głos robota), głęboki głos (narrator), głos Skye, ciepły głos, głos Bestie, głos Bohatera (głos Chiris), empatyczny głos, poważny głos, głos Joey, głos Stitch, głos Stormtroopera (Star Wars), głos Rocket (Guardians of the Galaxy).',
    answer1_2: 'Głosy AI TikTok, które wkrótce będą obsługiwane, to: głos Derek, głos Trickster, głos Austin Butler, głos reportera, głos Adam, głos psa, głos Miley Cyrus, głos Alexa, głos obcego, głos zwierzęcia, głos dziecka, głos komputera, głos wiewiórki, głos echo, głos npr.',
    answer1_3: 'Ponadto, narzędzie do generowania głosów TikTok obsługuje kilka innych języków, w tym chiński, japoński, koreański, wietnamski, tajski, hindi, perski, rosyjski, niemiecki, francuski, rumuński, czeski, hiszpański, portugalski, bengalski, włoski, arabski, urdu, tradycyjny chiński i malajski.',
    answer1_4: 'Jeśli pilnie potrzebujesz konkretnego głosu, wyślij mi e-mail.',

quest2: 'Jakie są zalety generatora głosu TikTok?',
    answer2_1: '- Narzędzie do generowania głosów TikTok może generować różne typy głosów, które są często używane w filmach TikTok.',
    answer2_2: '- Jest to najnowszy generator głosów AI, który może generować głosy tts TikTok podobne do ludzkich.',
    answer2_3: '- Jest bardziej wygodny do edycji wideo na PC.',
    answer2_4: '- Możesz używać niektórych głosów, które obecnie nie są dostępne w aplikacji tts TikTok.',

quest3: 'Jak korzystać z generatora głosu TikTok?',
    answer3_1: 'Generator głosu TikTok jest bardzo łatwy w użyciu:',
    answer3_2: '- Wybierz język i akcent głosu.',
    answer3_3: '- Wpisz tekst, który ma zostać przekonwertowany na mowę, w polu wprowadzania.',
    answer3_4: '- Naciśnij przycisk generowania i poczekaj kilka sekund.',
    answer3_5: '- Odtwórz lub pobierz głos AI TikTok.',

quest4: 'Jakie są najbardziej znane i zabawne głosy TikTok?',
    answer4_1: 'Najbardziej znanym męskim głosem TikTok jest głęboki głos, oficjalnie nazywany narratorem.',
    answer4_2: 'W przypadku najbardziej zabawnego głosu TikTok, osobiście polecam głos ghostface i głos C3PO. Te głosy są często używane do dubbingowania zabawnych filmów TikTok, ponieważ są łatwe do rozpoznania i mogą szybko przyciągnąć widzów.',

quest5: 'Jakie są najbardziej irytujące głosy TikTok?',
    answer5_1: 'Myślę, że tak naprawdę nie ma "irytującego" głosu TikTok.',
    answer5_2: 'Bardziej chodzi o kontekst i treść, w której głos jest używany, co może sprawić, że niektóre osoby poczują się nieswojo. Na przykład, niektórzy ludzie mogą uznać głos Jessie za irytujący, ponieważ pojawiał się zbyt często na TikTok w określonym okresie. Jednakże, ze względu na jego ton i jakość, jest to w rzeczywistości fantastyczny efekt głosu TikTok. Ponadto, aktorka głosowa TikTok stojąca za nim jest bardzo profesjonalna i sama publikuje swoje filmy na TikTok.',
    answer5_3: 'Dlatego sugeruję, aby wszyscy odłożyli na bok swoje obawy i uprzedzenia, posłuchali kilkakrotnie głosów z tego narzędzia i cierpliwie znaleźli efekt głosu TikTok, który najlepiej do nich pasuje.',

quest6: 'Jak korzystać z generatora głosu TikTok do generowania głosu Jessie?',
    answer6_1: '- W pierwszym menu rozwijanym narzędzia do generowania głosu TikTok wybierz English (US), a następnie w drugim menu rozwijanym wybierz głos Jessie (głos żeński).',
    answer6_2: '- Wpisz tekst w polu wprowadzania i kliknij przycisk generowania.',
    answer6_3: '- Poczekaj kilka sekund lub do dziesięciu sekund, usłyszysz głos AI. W pasku narzędzi znajdź przycisk ze strzałką w dół i kliknij go, aby pobrać.',

quest7: 'Jak korzystać z generatora głosu TikTok do generowania głosu C3PO?',
    answer7_1: '- W pierwszym menu rozwijanym narzędzia do generowania głosu TikTok wybierz English (US), a następnie w drugim menu rozwijanym wybierz głos C3PO (głos robota).',
    answer7_2: '- Wpisz tekst w polu wprowadzania i kliknij przycisk generowania.',
    answer7_3: '- Poczekaj kilka sekund lub do dziesięciu sekund, usłyszysz głos AI. W pasku narzędzi znajdź przycisk ze strzałką w dół i kliknij go, aby pobrać.',

quest8: 'Jak korzystać z generatora głosu TikTok do generowania głosu ghostface?',
    answer8_1: '- W pierwszym menu rozwijanym narzędzia do generowania głosu TikTok wybierz English (US), a następnie w drugim menu rozwijanym wybierz głos ghostface.',
    answer8_2: '- Wpisz tekst w polu wprowadzania i kliknij przycisk generowania.',
    answer8_3: '- Poczekaj kilka sekund lub do dziesięciu sekund, usłyszysz głos AI. W pasku narzędzi znajdź przycisk ze strzałką w dół i kliknij go, aby pobrać.',

quest9: 'Jak korzystać z generatora głosu TikTok do generowania głosu Siri?',
    answer9_1: '- W pierwszym menu rozwijanym narzędzia do generowania głosu TikTok wybierz English (US), a następnie w drugim menu rozwijanym wybierz głos Siri.',
    answer9_2: '- Wpisz tekst w polu wprowadzania i kliknij przycisk generowania.',
    answer9_3: '- Poczekaj kilka sekund lub do dziesięciu sekund, usłyszysz głos AI. W pasku narzędzi znajdź przycisk ze strzałką w dół i kliknij go, aby pobrać.',

quest10: 'Jak dodać efekt głosu TikTok do wideo TikTok?',
    answer10_1: 'Jeśli chcesz dowiedzieć się, jak korzystać z oficjalnych głosów TikTok na TikTok, wkrótce napiszę dedykowany artykuł na ten temat.',
    answer10_2: 'Tutaj wyjaśnię, jak opublikować swój głos na TikTok po jego wygenerowaniu i pobraniu z generatora głosu TikTok.',
    answer10_3: "1. Jeśli wygenerowałeś głos TikTok na swoim komputerze i chcesz go przesłać na TikTok lub do innej aplikacji do edycji wideo na swoim telefonie, musisz przenieść plik głosu na swój telefon. Dla iPhone'ów możesz użyć airDrop do transferu. Dla telefonów z Androidem, nie jestem zbyt zaznajomiony z tym procesem, ale z pewnością znajdziesz sposoby i narzędzia do tego.",
    answer10_4: '2. Gdy plik głosu zostanie przeniesiony na twój telefon, możesz otworzyć TikTok:',
    answer10_5: '- Naciśnij przycisk "+" na dole interfejsu, wybierz wideo na swoim telefonie.',
    answer10_6: '- Po przesłaniu wideo, znajdź kwadratową ikonę po prawej stronie ekranu, aby przejść do strony edycji.',
    answer10_7: '- Na dole strony znajdź przycisk "dodaj głos" i naciśnij go, aby wybrać właśnie przeniesiony plik głosu.',
    answer10_8: '- W tym interfejsie możesz dokonać pewnych dostosowań wideo, a następnie naciśnij przycisk w prawym górnym rogu, aby opublikować.',

quest11: 'Czy TikTok Voice jest darmowy?',
    answer11: 'Tak, jest to darmowe narzędzie do generowania głosów AI TikTok z tekstu na mowę.',

quest12: 'Gdzie mogę wygenerować popularny głos TikTok do swojego wideo TikTok?',
    answer12: 'Możesz odwiedzić https://tiktokvoice.net/, aby dodać głosy AI TikTok do swojego wideo.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}